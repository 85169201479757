import React from 'react';

import Section from '../../../molecules/Section/Section';
import { customInnerContentStyles } from '../styles/HeroNewStyles';
import { Content, Heading, Subhead } from '../styles/NewsroomHeroStyles';

const BlogHero = () => (
  <Section innerStyle={customInnerContentStyles} bgColor="#000000000">
    <Content>
      <Heading>Welcome to the Shopmonkey Blog</Heading>
      <Subhead>
        Insider info and advice for the automotive care industry, covering
        everything from streamlining operations to keeping customers happy and
        growing your business.
      </Subhead>
    </Content>
  </Section>
);

export default BlogHero;
